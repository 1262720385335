<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import WindowLoading from "../../../../layout/window/Loading";
import WindowSuccess from "../../../../layout/window/Success"
import TimerCloseWindow from "../../../../layout/window/TimerClose"
import ERow from "../../../../layout/components/Row";
import ECol from "../../../../layout/components/Col";
import {list} from "@/domain/processos/services/comarca";
import {newVara, updateVara, find} from "@/domain/processos/services/vara";

const mockContato = {
  nome: null,
  telefone: null,
  email: null
}

export default {
  name: 'VaraWindow',
  props: ['id'],
  components: {
    WindowContent,
    WindowFooter,
    ErpSField,
    ErpInput,
    ERow,
    ECol,
    WindowLoading,
    ErpSelect,
    WindowSuccess,
    TimerCloseWindow
  },
  data () {
    return {
      vara: {
        id: null,
        nome: null,
        comarca: null,
        contatos: []
      },
      comarcas: [],
      loading: !!this.id,
      status: null,
      loadingComarcas: false,
      errors: null,
      tmpValor: JSON.parse(JSON.stringify(mockContato)),
      success: false
    }
  },
  created () {
    this.loadComarcas()
  },
  mounted () {
    this.load()
  },
  methods: {
    loadComarcas () {
      this.loadingComarcas = true
      list(5000, 1, '')
          .then(response => {
            this.comarcas = (response.data.result || []).map(c => ({label: `${c.codigo} - ${c.nome}`, value: c.id}))
          })
          .catch((error) => {
            this.alertApiError(error)
            console.log(error)
          })
          .finally(() => {
            this.loadingComarcas = false
          })
    },
    load () {
      this.id && find(this.id)
          .then(response => {
            this.vara = response.data
            this.vara.comarca = this.vara.comarca.id
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os dados da vara, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    save () {
      this.loading = true
      this.status = 'Processando vara'
      const data = {...this.vara}
      this.verificaTmpValor()
      data.contatos = data.contatos.map(c => {
        c.telefone = (c.telefone || '').replace(/\D/g, '')
        return c
      })
      const method = data.id ? updateVara(data.id, data) : newVara(data)
      method
          .then(({data}) => {
            this.vara.id = data.id
            this.success = true
            this.$nextTick(() => {
              this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    open () {
      console.log('TODO open')
    },
    addValor () {
      let {nome, telefone, email} = this.tmpValor
      nome = (nome || '').trim()
      telefone = (telefone || '').trim()
      email = (email || '').trim()
      if (!nome) return this.$uloc.notify({type: 'warning', message: 'Informe um nome para o contato'})
      if (!telefone && !email) return this.$uloc.notify({type: 'warning', message: 'Informe pelo menos um telefone ou email'})
      const valorJaExiste = this.vara.contatos.some(c => c.nome === nome && c.telefone === telefone && c.email === email)
      if (valorJaExiste) return this.$uloc.notify({type: 'warning', message: 'Valor já adicionado'})
      this.vara.contatos.push({nome, telefone, email})
      this.tmpValor = JSON.parse(JSON.stringify(mockContato))
      setTimeout(() => {
        this.$refs.adicionaValor.focus()
      }, 100)
    },
    removeValor (index) {
      this.vara.contatos.splice(index, 1)
    },
    verificaTmpValor () {
      let {nome, telefone, email} = this.tmpValor
      nome = (nome || '').trim()
      telefone = (telefone || '').trim()
      email = (email || '').trim()

      if (nome || telefone || email) this.addValor()
    }
  }
}
</script>

<template>
  <window-content v-if="!loading">
    <div class="m-t">
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="vara.id" disable />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>

        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="110px"
          >
            <erp-input simple-border v-model="vara.nome" required autofocus />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Comarca:"
              label-width="110px"
          >
            <erp-select
                placeholder="Qualquer"
                :options="comarcas"
                v-model="vara.comarca"
                :loading="loadingComarcas"
                required
            ></erp-select>
          </erp-s-field>
        </e-col>
      </e-row>

      <p class="font-11 font-bold m-t-lg">Contatos</p>
      <e-row class="row-valores" v-for="(contato, index) in vara.contatos" :key="index">
        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="70px"
          >
            <div style="display: flex">
              <erp-input simple-border :value="contato.nome" readonly />
            </div>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 180px">
          <erp-s-field
              view="lr"
              label="Telefone:"
              label-width="70px"
          >
            <div style="display: flex">
              <erp-input simple-border :value="contato.telefone" readonly />
            </div>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 400px">
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="70px"
          >
            <div style="display: flex">
              <erp-input simple-border :value="contato.email" readonly />
              <u-icon name="times" type="fa" icon-style="light"
                      class="cursor-pointer m-l-sm text-negative"
                      @click.native="removeValor(index)"
                      v-if="contato"
              />
            </div>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-md">
        <e-col style="display: flex; max-width: 250px">
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="70px"
          >
            <erp-input simple-border v-model="tmpValor.nome" ref="adicionaValor" @keyup.native.enter="addValor" />
          </erp-s-field>
        </e-col>
        <e-col style="display: flex; max-width: 200px">
          <erp-s-field
              view="lr"
              label="Telefone:"
              label-width="70px"
          >
            <erp-input simple-border v-model="tmpValor.telefone" @keyup.native.enter="addValor" v-mask="'(##)# ####-####'" />
          </erp-s-field>
        </e-col>
        <e-col style="display: flex; max-width: 400px">
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="70px"
          >
            <erp-input simple-border v-model="tmpValor.email" @keyup.native.enter="addValor" />
          </erp-s-field>
          <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="addValor" />
        </e-col>
      </e-row>
    </div>

    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
      </div>
      <h5 class="m-t" >{{ vara.id ? 'Atualizado com sucesso!' : 'Vara registrada com sucesso!' }}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" />
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else></window-loading>
</template>
